<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_breadcrumb">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/Admin/index' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>交易查询</el-breadcrumb-item>
                    <el-breadcrumb-item>账户查询</el-breadcrumb-item>
                    <el-breadcrumb-item>贡献值调整</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <el-form ref="form" :model="form" label-width="180px">
            <div class="admin_main_block admin_m15">
                <el-col :span="24">
                    <span class="grid-divider">查询用户信息</span>
                    <el-divider></el-divider>
                </el-col>
                <el-row>
                    <el-col :span="span" class="hight">
                        <el-form-item label="手机号码（会员账号）">
                            <el-input @blur="getUserinfo" placeholder="输入手机号查询" v-model="phone" ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </div>
            <div class="admin_main_block admin_m15">
                <el-col :span="24">
                    <span class="grid-divider">调整值</span>
                    <el-divider></el-divider>
                </el-col>
                <el-row>
                    <el-col :span="span" class="hight">
                        <el-form-item label="类型">
                            <el-radio-group v-model="form.type" :disabled="disabled">
                                <el-radio-button label="1">增加</el-radio-button>
                                <el-radio-button label="0">减少</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="span" class="hight">
                        <el-form-item label="账户类型">
                            <el-radio-group v-model="form.moneyType" @change="form.singleMaxLimit = null" :disabled="disabled">
                                <el-radio-button label="1">惠豆</el-radio-button>
                                <el-radio-button label="2">金额</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="span" class="hight">
                        <el-form-item label="贡献值">
                            <el-input-number
                                    v-model="form.singleMaxLimit"
                                    :disabled="disabled"
                                    controls-position="right"
                                    :placeholder="'请输入值'+condition[form.moneyType].placeholder+'范围'"
                                    :min="condition[form.moneyType].min"
                                    :precision="condition[form.moneyType].precision"
                                    :step="condition[form.moneyType].step"
                                    :max="condition[form.moneyType].max"
                                    class="price"
                                    clearable
                            ></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>
            </div>
            <div class="admin_main_block admin_m15">
                <el-row :gutter="40">
                    <el-col :span="24">
                        <el-form-item label="" >
                            <el-button icon="el-icon-check" type="primary" @click="$clickBtn(success('form'))" :disabled="disabled">
                                提交
                            </el-button>
                        </el-form-item>

                    </el-col>
                </el-row>
            </div>
        </el-form>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                disabled: true,
                span: 8,
                phone:'',
                form: {
                    phone:'',
                    type:'1',
                    moneyType:'2',
                    singleMaxLimit:'',
                },
                condition:{
                    '1':{
                        placeholder:'0.01-20000',
                        min:0.01,
                        precision:2,
                        step:0.01,
                        max:20000,
                    },
                    '2':{
                        placeholder:'0.0001-20000',
                        min:0.0001,
                        precision:4,
                        step:0.0001,
                        max:20000,
                    },
                },
            };
        },
        methods: {
            getUserinfo() {
                if (this.$isEmpty(this.phone)) {
                    return false;
                }
                this.$get(this.$api.findSuperiorByPhone + this.phone, {}).then(
                    (res) => {
                        if (res.code === 1) {
                            this.form = res.data;
                            this.disabled = false;
                        } else {
                            this.$message({
                                type: "error",
                                message: res.msg,
                            });
                        }
                    }
                );
            },
            success(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.$confirm("是否继续?", "提示", {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消",
                            type: "warning",
                        })
                            .then(() => {
                                //修改||新增根据id进行判断
                                let url = this.$api.profitCashLimitAdd;
                                this.post(url, this.form);
                            })
                            .catch(() => {
                                this.$message({
                                    type: "info",
                                    message: "已取消",
                                });
                            });
                    } else {
                        this.$message({
                            type: "warning",
                            message: "请填写完整信息！",
                        });
                        return false;
                    }
                });
            },
            post(url, data) {
                this.$post(url, data).then((res) => {
                    if (res.code === 1) {
                        this.$message({
                            type: "success",
                            message: "成功",
                        });
                    } else {
                        this.$message({
                            type: "error",
                            message: res.msg,
                        });
                    }
                });
            },
        },
        created() {
        },
    };
</script>
<style lang="scss" scoped>
    .el-card {
        text-align: right;
        margin-top: 30px;
    }

    .hight {
        /*height: 60px;*/
    }

    .el-select {
        width: 100%;
    }

    .el-input {
        width: 100%;
    }

    .el-divider--horizontal {
        margin: 12px 0 12px 0;
    }

    .price {
        width: 200px;
    }

    .el-input-number--small {
        width: 200px;
    }
</style>
